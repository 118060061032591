import React from 'react';

const AboutMe = () => {

    return (
        <div>
            <h1 id='txt'> Это AboutMe</h1>
        </div>
    );
}

export default AboutMe;