import React from 'react';

const SolutionsInfo = () => {

    return (
        <div className="SolutionsInfo">
            <h1 id='txt'> Это SolutionsInfo</h1>
        </div>
    );
}

export default SolutionsInfo;