import React from 'react';

const PricesInfo = () => {

    return (
        <div className="PricesInfo">
            <h1 id='txt'> Это PricesInfo</h1>
        </div>
    );
}

export default PricesInfo;