import React, { useState, useEffect, useRef } from 'react';
import './styles/DetailBoard.css';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';

const DetailBoard = () => {
    const [boardid, setboardid] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const timerRef = useRef(null);
    const [group, setGroup] = useState([]);
    const [currentgroup, setCurrentGroup] = useState([]);
    const [task, setTask] = useState([]);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [modalIsOpen3, setIsOpen3] = useState(false);
    const [modalIsOpenGroup, setIsOpenGroup] = useState(false);
    const [performer, setperformer] = useState([]);
    const [titlegroup, settitlegroup] = useState('');
    const [column, setColumnChange] = useState('');
    const [currenttask, setCurrenttask] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [canViewGroup, setCanViewGroup] = useState(false);
    const [canViewTasks, setCanViewTasks] = useState(false);
    const [canAddTask, setCanAddTask] = useState(false);
    const [canAddGroup, setCanAddGroup] = useState(false);
    const [canChangeGroup, setCanChangeGroup] = useState(false);
    const [sortedDeadlineTask, setSortedDeadlineTask] = useState(false);
    const [boardIsPrivate, setBoardIsPrivate] = useState(false);
    const [authorBoard, setAuthorBoard] = useState("");
    const [maxTaskPerformerInStatus, setMaxTaskPerformerInStatus] = useState();
    const [allowCreateTaskInStatusCompleted, setAllowCreateTaskInStatusCompleted] = useState(false);
    let [taskfortask, setTaskForTask] = useState([]);
    const [debouncedTask, setDebouncedTask] = useState(task);
    const [loading, setLoading] = useState(true);
    const [systemStatus, setSystemStatus] = useState([]);
    const [selectedSystemStatus, setSelectedSystemStatus] = useState([]);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;

    const getboardid = async () => {
        await api.get(`${apiUrl}api/v1/boards/${window.location.pathname.replace(/\/$/, '').split('/').pop()}`, {
            headers: {    
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('titleboards', response.data.title);
                localStorage.setItem('slugboards', response.data.slug);
                localStorage.setItem('boardID', response.data.id);
                setboardid(response.data.id);
                setBoardIsPrivate(response.data.private_board);
                let author = response.data.author;
                api.get(`${apiUrl}api/identity/auth/users/me/`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data.username === author) {
                            setAuthorBoard(response.data.author);
                            setCanAddGroup(true);
                        }
                        localStorage.setItem('username', response.data.username);
                    }
                }).catch(error =>{
                    console.error(error);
                });
            }
        }).catch(error => {
            if (error.response.status === 403) {
                alert("Доска не найдена");
                window.location.href = "/main";
            }
        });
    }

    const handlecreategroup = () => {
        api.get(`${apiUrl}api/v1/system-status/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setSystemStatus(response.data);
                setSelectedSystemStatus(response.data
                    .filter(it => it.name === 'Открытые')
                    .map(it => it.id).toString());
                setIsOpen3(true);
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleChangeSelectSystemStatus = (event) => {
        var select = window.confirm("При смене статуса состояния, все задачи в текущем состоянии примут новый статус состояния. Продолжить?");
        if (select) {
            setSelectedSystemStatus(event.target.value);
            api.patch(`${apiUrl}api/v1/groups/${selectedGroup.groupid}/update-system-status/`, {
                'system_status': event.target.value
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
            ).then((response) => {
                if (response.status === 200) {
                    closeModal();
                    updateall();
                }
            })
            .catch(error =>{
                console.error(error);   
            });
        }
    };

    const handleChangeSelectSystemStatusForNewGroup = (event) => {
        setSelectedSystemStatus(event.target.value);
    };

    const handleSubmitCreateGroup = (event) => {
        event.preventDefault();
        if (group.some((group => group.title === column && group.board_id === boardid))) {
            alert('Группа с таким названием уже существует');
        }
        else if (!selectedSystemStatus){
            alert("Выберите статус состояния");
        }
        else {
            api.post(`${apiUrl}api/v1/groups/`, {
                'title': column,
                'board_id': boardid,
                'system_status': selectedSystemStatus,
                'max_count_task_for_one_user': maxTaskPerformerInStatus,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
            ).then((response) => {
                if (response.status === 201) {
                    updateall();
                    setMaxTaskPerformerInStatus();
                    closeModal();
                }
            })
            .catch(error =>{
                console.error(error);   
            });
        }
    };

    function handleOpendetailTasks(data) {
        window.location.href = `/task/${data.taskid}`;
    };

    function handleOpendetailTasksNewPin(e, data) {
        if (e.button === 1) {
            e.preventDefault();
            window.open(`/task/${data.taskid}`);
        }
    };

    const handledColumnChange = (event) => {
        if (event.target.value.length <= 50) {
            setColumnChange(event.target.value);
        } else {
            return;
        }
    };

    function closeModal() {
        setIsOpen3(false);
        setIsOpenGroup(false);
        setMaxTaskPerformerInStatus();
    };

    function openModal(data) {
        localStorage.setItem('grouptaskcreate', data.grouptitle);
        localStorage.setItem('grouptasksystemstatus', data.groupsystem_status);
        localStorage.setItem('grouptaskidcreate', data.group_id);
        localStorage.setItem('relatedtaskid', '');
        window.location.href = "/create-task";
    };

    const openModalNewPin = (e, data) => {
        if (e.button === 1) {
            e.preventDefault();
            localStorage.setItem('grouptaskcreate', data.grouptitle);
            localStorage.setItem('grouptasksystemstatus', data.groupsystem_status);
            localStorage.setItem('grouptaskidcreate', data.group_id);
            localStorage.setItem('relatedtaskid', '');
            window.open("/create-task");
        }
    };

    const handleTextareachange = (event) => {
        const inputValue = event.target.value;
        const lastIndex = inputValue.lastIndexOf('\n');
        if (lastIndex !== -1) {
          event.target.value = inputValue.substring(0, lastIndex);
        }
        settitlegroup(event.target.value);
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected){
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    const handleTextAreaBlur = (data) => {
        setIsTextSelected(false);
        if (titlegroup && data.title !== titlegroup) {
            const group_id = data.group_id;
            api.patch(`${apiUrl}api/v1/groups/${group_id}/`, {
                'title': titlegroup,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleDeleteGroup = () => {
        const result = window.confirm("ты подумай сейчас ок нажмешь и все удалится");
        if (result) {
            const group_id = selectedGroup.groupid;
            api.delete(`${apiUrl}api/v1/groups/${group_id}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    window.location.href = window.location;
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleopenaccessdeletegroup = (data) => {
        if (canChangeGroup) {
            setSelectedSystemStatus(data.system_status);
            setSelectedGroup(data);
            setIsOpenGroup(true);
            setMaxTaskPerformerInStatus(data.max_count_task_for_one_user);
            api.get(`${apiUrl}api/v1/system-status/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 200) {
                    setSystemStatus(response.data);
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                if (error.response.status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                }
            });
        }
        else {
            alert("Недостаточно прав для изменения статуса, обратитесь к администратору");
        }
    };

    function updateall() {
        getboardid();
        const CheckPermission = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can view Доска')) {
                    }
                    else {
                        if (!authorBoard) {
                            window.location.href = '/main';
                        }
                    }
                    if (response.data.includes('Can add Задача')) {
                        setCanAddTask(true);
                    }
                    if (response.data.includes('Can add Группа')) {
                        setCanAddGroup(true);
                    }
                    if (response.data.includes('Can change Группа')) {
                        setCanChangeGroup(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanAddGroup(true);
                        setCanAddTask(true);
                        setCanChangeGroup(true);
                    }
                    if (authorBoard) {
                        setCanAddGroup(true);
                        setCanAddTask(true);
                        setCanChangeGroup(true);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        };
        const getInfoOrganization = async() => {
            await api.get(`${apiUrl}api/v1/organizations/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 200) {
                    setAllowCreateTaskInStatusCompleted(response.data[0].allow_create_task_in_status_completed);
                }
            }).catch(error => {});
        }
        const getGroupTask = async() => {
            await api.get(`${apiUrl}api/v1/tasks/`, {
                params: {
                    'slugboard': window.location.pathname.replace(/\/$/, '').split('/').pop()
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setCanViewTasks(true);
                    setTask(response.data);
                    if (response.data.length === 0) {
                        setLoading(false);
                    }
                }
            }).catch(error => {});
            await api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': window.location.pathname.replace(/\/$/, '').split('/').pop()
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setCanViewGroup(true);
                    if (response.data.length !== 0) {
                        setGroup(response.data);
                    }
                    else {
                        setGroup([]);
                    }
                }
            }).catch(error => {});
        }
        getGroupTask();
        CheckPermission();
        getInfoOrganization();
        fetchAllUsers();
    };
    useEffect(() => {
        updateall();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTask(task);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [task]);

    useEffect(() => {
        if (debouncedTask.length > 0) {
            api.get(`${apiUrl}api/identity/auth/users/me/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.default_filters_for_boards.length !== 0) {
                        if (response.data.default_filters_for_boards.created_at_bottom) {
                            setSortedCreatedAtTask(true);
                            handleFilterTaskOnDate(null, 'created_at');
                        }
                        else if (response.data.default_filters_for_boards.created_at_top) {
                            setSortedCreatedAtTask(false);
                            handleFilterTaskOnDate(null, 'created_at');
                        }
                        else if (response.data.default_filters_for_boards.priority_bottom) {
                            setSortedPriorityTask(false);
                            handleFilterTaskOnDate(null, 'priority');
                        }
                        else if (response.data.default_filters_for_boards.priority_top) {
                            setSortedPriorityTask(true);
                            handleFilterTaskOnDate(null, 'priority');
                        }
                        else if (response.data.default_filters_for_boards.deadline_bottom) {
                            setSortedDeadlineTask(true);
                            handleFilterTaskOnDate(null, 'deadline');
                        }
                        else if (response.data.default_filters_for_boards.deadline_top) {
                            setSortedDeadlineTask(false);
                            handleFilterTaskOnDate(null, 'deadline');
                        }
                    }
                    setLoading(false);
                }
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        }
    }, [debouncedTask]);

    function dragStartHandler(e, task, group) {
        setCurrenttask(task);
        setCurrentGroup([]);
    };

    function dragStartHandlerGroup(e, group) {
        setCurrentGroup(group);
        setCurrenttask([]);
    };

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } else {
                    setperformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function dragLeaveHandler(e) {
        e.target.style.boxShadow = 'none';
        if (e.target.id === 'detailtask') {
            e.target.style.marginTop = '0px';
            e.target.style.marginBottom = '0px';
        }
        if (e.target.id === 'drop-for-task' ) {
            e.target.style.boxShadow = 'none';
            e.target.style.height = '10px';
        }
        if (e.target.id === 'drop-for-group-div') {
            e.target.style.width = '20px';
            e.target.style.boxShadow = 'none';
        }
    };

    function dragEndHandler(e) {
        e.target.style.boxShadow = 'none';
        if (e.target.id === 'detailtask' ) {
            e.target.style.marginTop = '0px';
            e.target.style.marginBottom = '0px';
        }
        if (e.target.id === 'drop-for-task' ) {
            e.target.style.boxShadow = 'none';
            e.target.style.height = '10px';
        }
        if (e.target.id === 'drop-for-group-div') {
            e.target.style.width = '10px';
            e.target.style.boxShadow = 'none';
        }
        setCurrenttask([]);
        setCurrentGroup([]);
    };
    
    function dragOverHandler(e, task) {
        e.preventDefault();
        if (currenttask.length !== 0) {
            if (e.target.id === 'detailtask') {
                if (task) {
                    if (task !== currenttask) {
                        setTaskForTask(task);
                    }
                }
            }
            if (e.target.id === 'drop-for-task') {
                if (task) {
                    console.log(task)
                    if (task !== currenttask) {
                        setTaskForTask(task);
                        e.target.style.height = '100px';
                    }
                }
            }
        }
        if (currentgroup.length !== 0) {
            if (task) {
                if (task.parent === currentgroup.group_id) {}
                else if (currentgroup === task) {}
                else if (e.target.id === 'drop-for-group-div') {
                    e.target.style.width = `200px`;
                    e.target.style.boxShadow = '0px 0px 0px 8px rgb(104, 70, 137, 1)';
                }
            }
        }
    };

    function dragDropHandlerForGroup(e, task, group){
        e.preventDefault();
        if (currentgroup.length === 0) {
            api.patch(`${apiUrl}api/v1/tasks/${currenttask.task_id}/update-group/`, {
                'group': group.group_id,
                'parent': null,
                'child': null
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                }
            }).catch(error =>{
                console.error(error);
                const status = error.response ? error.response.status : null;
                if (error.response.status === 403) {
                    if (error.response.data.performer) {
                        alert(`Код ошибки: ${status}, ${error.response.data.performer}`, error);
                    }
                }
            });
        }
        setCurrenttask([]);
    };

    function dragDropHandlerGroup(e, group) {
        e.preventDefault();
        if (group === currentgroup) {}
        else if (group.parent === currentgroup.group_id){}
        else {
            if (currenttask.length === 0) {
                api.patch(`${apiUrl}api/v1/groups/${currentgroup.group_id}/`, {
                    'group': group.group_id
                },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setGroup([]);
                        updateall();
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
        if (e.target.id === 'drop-for-group-div') {
            e.target.style.width = `20px`;
            e.target.style.boxShadow = 'none';
        }
        setCurrentGroup([]);
    };

    function dragDropHandler(e, task, group) {
        e.preventDefault();
        if (currenttask === task) {}
        else{
            if (currenttask.group === group.group_id){
                if (currenttask.parent === task.parent) {}
                if (currenttask.task_id === task.parent) {}
                else {
                    if (currentgroup.length === 0) {
                        api.patch(`${apiUrl}api/v1/tasks/${currenttask.task_id}/`, {
                            'task': task.task_id
                        },{
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                        }).then((response) => {
                            if (response.status === 200) {
                                updateall();
                            }
                        }).catch(error => {
                            console.error(error);
                            const status = error.response ? error.response.status : null;
                            if (error.response.status === 403) {
                                if (error.response.data.performer) {
                                    alert(`Код ошибки: ${status}, ${error.response.data.performer}`, error);
                                }
                            }
                        });
                        updateall();
                        task = [];
                    }
                }
            } 
            else {
                if (currentgroup.length === 0) {
                    api.patch(`${apiUrl}api/v1/tasks/${currenttask.task_id}/`, {
                        'group': task.group,
                        'task': task.task_id
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }).then((response) => {
                        if (response.status === 200) {
                            console.log("currenttask was been updated");
                            updateall();
                        }
                    }).catch(error => {
                        console.error(error);
                        const status = error.response ? error.response.status : null;
                        if (error.response.status === 403) {
                            if (error.response.data.performer) {
                                alert(`Код ошибки: ${status}, ${error.response.data.performer}`, error);
                            }
                        }
                    });
                }
            }
        }
        if (e.target.id === 'detailtask') {
            e.target.style.marginTop = '0px';
            e.target.style.marginBottom = '0px';
        }
        if (e.target.id === 'drop-for-task') {
            e.target.style.boxShadow = 'none';
            e.target.style.height = '10px';
        }
        setCurrenttask([]);
        // updateall();
    };

    const [typesortaccept, settypesortaccept] = useState("");
    const [sortTopOrBottom, setSortTopOrBottom] = useState();
    const [sortedCreatedAtTask, setSortedCreatedAtTask] = useState(false);
    const [sortedPriorityTask, setSortedPriorityTask] = useState(false);
    const handleFilterTaskOnDate = (e, type) => {
        if (e) {
            e.preventDefault();
        }
        if (type === 'deadline') {
            setTask(task.sort((a, b) => {
                const dateA = Date.parse(a.deadline) || Infinity;
                const dateB = Date.parse(b.deadline) || Infinity;
                return sortedDeadlineTask ? dateB - dateA : dateA - dateB;
            }));
            if (sortedDeadlineTask === true) {
                settypesortaccept("сроку сдачи(убывание)");
                setSortTopOrBottom(false);
            }
            else {
                settypesortaccept("сроку сдачи(возрастание)");
                setSortTopOrBottom(true);
            }
            setSortedDeadlineTask(!sortedDeadlineTask);
        }
        if (type === 'created_at') {
            setTask(task.sort((a, b) => {
                const dateA = Date.parse(a.created_at) || Infinity;
                const dateB = Date.parse(b.created_at) || Infinity;
                return sortedCreatedAtTask ? dateB - dateA : dateA - dateB;
            }));
            if (sortedCreatedAtTask === true) {
                settypesortaccept("дате создания(убывание)");
                setSortTopOrBottom(false);
            }
            else {
                settypesortaccept("дате создания(возрастание)");
                setSortTopOrBottom(true);
            }
            setSortedCreatedAtTask(!sortedCreatedAtTask);
        }
        if (type === 'priority') {
            setTask(task.sort((a, b) => {
                const priorityA = a.priority || Infinity;
                const priorityB = b.priority || Infinity;
                return sortedPriorityTask ? priorityB - priorityA : priorityA - priorityB;
            }));
            if (sortedPriorityTask === true) {
                settypesortaccept("приоритету(возрастание)");
                setSortTopOrBottom(true);
            }
            else {
                settypesortaccept("приоритету(убывание)");
                setSortTopOrBottom(false);
            }
            setSortedPriorityTask(!sortedPriorityTask);
        }
    };

    const handleOpenModalSaveDefaultSort = () => {
        if (typesortaccept) {
            let selectedDefaultFilters = {};
            if (typesortaccept === "дате создания(убывание)") {
                selectedDefaultFilters = {
                    "created_at_bottom": 1
                }
            }
            else if (typesortaccept === "дате создания(возрастание)") {
                selectedDefaultFilters = {
                    "created_at_top": 1
                }
            }
            else if (typesortaccept === "приоритету(возрастание)") {
                selectedDefaultFilters = {
                    "priority_top": 1
                }
            }
            else if (typesortaccept === "приоритету(убывание)") {
                selectedDefaultFilters = {
                    "priority_bottom": 1
                }
            }
            else if (typesortaccept === "сроку сдачи(убывание)") {
                selectedDefaultFilters = {
                    "deadline_bottom": 1
                }
            }
            else if (typesortaccept === "сроку сдачи(возрастание)") {
                selectedDefaultFilters = {
                    "deadline_top": 1
                }
            }
            if (window.confirm(`Вы хотите применить текущую сортировку по умолчанию: сортировка по ${typesortaccept}? Текущая сортировка будет видна только вам.`)) {
                api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                    'default_filters_for_boards': selectedDefaultFilters
                },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) { 
                        alert("Фильтры по умолчанию сохранены");
                    }
                }).catch(error => {
                    console.error(error);
                });
            } else {
                // settypesortaccept("");
            }
        }
    };

    const handleResetDefaultSort = () => {
        if (window.confirm(`Вы хотите сбросить фильтры по умолчанию?`)) {
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'default_filters_for_boards': {}
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    alert("Фильтры по умолчанию сброшены");
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            //или на бэк
            // let sortedDefaultParentTask = [];
            // task.forEach((ts) => {
            //     if (ts.parent === null) {
            //         sortedDefaultParentTask.push(ts);
            //         sortedDefaultParentTask = sortedDefaultParentTask.concat(task, ts.id)
            //     }
            // });
        }
    };

    const handleChangeMaxTaskPerformerStatus = (event) => {
        // const inputValue = event.target.value;
        if (/^[0-9]*$/.test(event.target.value)) {
            setMaxTaskPerformerInStatus(event.target.value);
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (maxTaskPerformerInStatus !== event.target.value) {
                if (event.target.value) {
                    api.patch(`${apiUrl}api/v1/groups/${selectedGroup.groupid}/`, {
                        'max_count_task_for_one_user': event.target.value
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }
                    ).then((response) => {
                        if (response.status === 200) {
                            setMaxTaskPerformerInStatus(event.target.value);
                            updateall();
                        }
                    })
                    .catch(error =>{
                        console.error(error);   
                    });
                }
            }
        }, 500);
    };

    document.title = `Доска ${localStorage.getItem('titleboards')} - ТвояЗадача`;

    if (loading) {
        return (
            <div 
                style={{ overflowX: 'auto',overflowY: 'hidden', width: "100%", height: "100vh", justifyContent: "space-between", alignItems: "left", display: "flex"}} 
                className="detail-board-page"
            >
                <div style={{minWidth: "100%", minHeight: "100%", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <video style={{maxHeight: "250px", maxWidth: "180px", borderRadius: "30px"}}
                        alt=""
                        src={apiUrlFrontend + 'loader.webm'}
                        type="video/webm"
                        autoPlay={1}
                        loop={1}
                        muted={1}
                    />
                </div>
            </div>
        )
    }

    return (
        <div
            style={{ overflowX: 'auto',overflowY: 'hidden', width: "100%", height: "100vh", justifyContent: "space-between", alignItems: "left", display: "flex",}} 
            className="detail-board-page"
        >
            <div id="for-scroll" style={{display: 'flex', width: "100%", height: "100%", marginLeft: "20px", marginTop: "100px"}}>
                <div style={{display: "flex", position: "fixed", width: '50px'}}>
                    <div id="div-with-lite-sort" style={{marginRight: "10px"}}>
                        <img id="sort-calendar-deadline-task-board"
                            onClick={(e) => handleOpenModalSaveDefaultSort(e)}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Установить как сортировка по умолчанию"
                            alt=""
                            src={apiUrlFrontend + 'default-settings-filter-detail-board.svg'}
                        >
                        </img>
                        {typesortaccept ?
                        <img id="sort-calendar-created-at-task-board"
                            onClick={(e) => handleResetDefaultSort(e)}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Сбросить сортировку по умолчанию"
                            alt=""
                            src={apiUrlFrontend + 'reset-default-filters-detail-board.svg'}
                        >
                        </img>: null}
                    </div>
                    <div id="div-with-lite-sort">
                        <img id="sort-calendar-deadline-task-board"
                            onClick={(e) => handleFilterTaskOnDate(e, 'deadline')}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Сортировка по истечению срока сдачи"
                            alt=""
                            src={apiUrlFrontend + 'calendar-sort-deadline.svg'}
                        >
                        </img>
                        <img id="sort-calendar-created-at-task-board"
                            onClick={(e) => handleFilterTaskOnDate(e, 'created_at')}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Сортировка по дате создания"
                            alt=""
                            src={apiUrlFrontend + 'calendar-sort-created-at.svg'}
                        >
                        </img>
                        <img id="sort-priority-task-board"
                            onClick={(e) => handleFilterTaskOnDate(e, 'priority')}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Сортировка по приоритету"
                            alt=""
                            src={apiUrlFrontend + 'sort-priority.svg'}
                        >
                        </img>
                        {typesortaccept ? sortTopOrBottom ?
                        <img id="sort-priority-task-board"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Сортировка по убыванию"
                            alt=""
                            src={apiUrlFrontend + 'sort-bottom.svg'}
                        >
                        </img>:
                        <img id="sort-priority-task-board"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Сортировка по возрастанию"
                            alt=""
                            src={apiUrlFrontend + 'sort-top.svg'}
                        >
                        </img>: null}
                    </div>
                </div>
                <Modal className="Modalforcreategroup"
                    isOpen={modalIsOpenGroup}
                    onRequestClose={closeModal}
                    overlayClassName="Overlayforcreategroup"
                >
                    <div id="info-group">
                        <div style={{display: 'flex'}}>
                            <select id="post-select-change"
                                value={selectedSystemStatus}
                                onChange={handleChangeSelectSystemStatus}
                            >
                                {systemStatus.map((system, index) => (
                                    <option key={index} value={system.id}>{system.name}</option>
                                ))}
                            </select>
                            <label id="hint-for-group-settings">
                                Свойство определяющее статус задач в данной колонке.
                            </label>
                        </div>
                        <div style={{display: "flex"}}>
                            <input id="task-max-performer-in-status"
                                placeholder='3'
                                value={maxTaskPerformerInStatus}
                                onChange={handleChangeMaxTaskPerformerStatus}
                            >
                            </input>
                            <label id="hint-for-group-settings">
                                Свойство определяющее максимальное количество задач на 1 исполнителя в данной колонке.
                            </label>
                        </div>
                        <button id="access-delete-group"
                            onClick={() => handleDeleteGroup()}
                        >
                            Удалить
                        </button>
                    </div>
                </Modal>
                <Modal className="Modalforcreategroup"
                    isOpen={modalIsOpen3}
                    onRequestClose={closeModal}
                    overlayClassName="Overlayforcreategroup"
                >
                    <form onSubmit={handleSubmitCreateGroup} className="modalformforgroup">
                        <div style={{overflow: 'hidden'}}>
                            <input
                                type="text"
                                id="titlegroupinput"
                                placeholder='Наименование колонки'
                                required
                                onChange={handledColumnChange}
                            >
                            </input>
                            <select id="post-select-change-for-new-column"
                                value={selectedSystemStatus}
                                onChange={handleChangeSelectSystemStatusForNewGroup}
                            >
                                {systemStatus.map((system, index) => (
                                    <option key={index} value={system.id}>{system.name}</option>
                                ))}
                            </select>
                            <label id="hint-for-group-settings">
                                Свойство определяющее статус задач в данной колонке
                            </label>
                            <div style={{display: "flex"}}>
                                <input id="task-max-performer-in-status"
                                    placeholder='3'
                                    value={maxTaskPerformerInStatus}
                                    onChange={handleChangeMaxTaskPerformerStatus}
                                >
                                </input>
                                <label id="hint-for-group-settings">
                                    Свойство определяющее максимальное количество задач на 1 исполнителя в данной колонке.
                                </label>
                            </div>
                            <button id="ModalSubmitgroup" type="submit">Создать</button>
                        </div>
                    </form>
                </Modal>
                <div id="divwithgroup">
                    {group.map((group, index) => (
                        <div id="div-for-animated-group-draggable">
                            <div id="group" key={index}>
                                {canViewGroup ?
                                    <div id="groupinput"
                                        draggable={true}
                                        onDragStart={(e) => dragStartHandlerGroup(e, group)}
                                        style={{display: "flex"}}
                                    >
                                        {canChangeGroup ?
                                            <textarea 
                                                type="text" 
                                                id="titlelabel"
                                                className="no-resize"
                                                maxLength={50}
                                                onClick={handleTextareaClick}
                                                onChange={handleTextareachange}
                                                onBlur={() => handleTextAreaBlur({title: group.title, group_id: group.group_id})}
                                            >
                                                {group.title}
                                            </textarea>:
                                            <textarea
                                                type="text" 
                                                id="titlelabel"
                                                readOnly='false'
                                                className="no-resize"
                                                maxLength={50}
                                                value={group.title}
                                                onClick={handleTextareaClick}
                                            >
                                            </textarea>
                                        }
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <div style={{display: "flex", alignItems: "right", justifyContent: "right"}}>
                                                {canAddTask ? allowCreateTaskInStatusCompleted === false && group.system_status_name === 'Completed' && boardIsPrivate === false ? null : 
                                                    <button 
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Создать новую задачу"
                                                        onClick={() => openModal({grouptitle: group.title, group_id: group.group_id, groupsystem_status: group.system_status})}
                                                        onMouseDown={(e) => openModalNewPin(e, {grouptitle: group.title, group_id: group.group_id, groupsystem_status: group.system_status})}
                                                        id="addtask"
                                                        // onDragOver={(e) => dragOverHandler(e)}
                                                        // onDrop={(e) => dragDropHandler(e, null, group)}
                                                    >
                                                        <img src={apiUrlFrontend + "plus-btn.svg"} alt='' id='btn-create-new-task-plus'/>
                                                    </button> : null
                                                }
                                                <button id="openaccessdeletegroup"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Настройки группы"
                                                    onClick={() => handleopenaccessdeletegroup({groupid: group.group_id, status: group.status, system_status: group.system_status, max_count_task_for_one_user: group.max_count_task_for_one_user})}>
                                                    <img src={apiUrlFrontend + "btn-menu-dot.svg"} alt=""/>
                                                </button>
                                            </div>
                                            {canViewTasks ? 
                                                <label id="hint-for-length-task-inside-group">
                                                    Всего {task.filter(task => task.group === group.group_id).length}
                                                </label>: null
                                            }
                                        </div>
                                    </div>: null
                                }
                                {canViewTasks ?
                                    task.filter(task => task.group === group.group_id).length ?
                                        <div id="divwiththegrouptasks"
                                            onDragOver={(e) => dragOverHandler(e)}
                                        >
                                            {task.filter(task => task.group === group.group_id).map((task, taskIndex) => (
                                                <div id="divforbuttuoncloseandbuttonopen" key={taskIndex}>
                                                    <div id="drop-for-task" index={taskIndex}
                                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                                        onDragEnd={(e) => dragEndHandler(e)}
                                                        onDragOver={(e) => dragOverHandler(e, task)}
                                                        onDrop={(e) => dragDropHandler(e, task, group)}
                                                    >
                                                    </div>
                                                    {task.priority <= 3 ?
                                                        <button id="detailtask"
                                                            draggable={true}
                                                            onClick={() => handleOpendetailTasks({taskid: task.task_id})}
                                                            onMouseDown={(e) => handleOpendetailTasksNewPin(e, {taskid: task.task_id})}
                                                            onDragStart={(e) => dragStartHandler(e, task, group)}
                                                            onDragLeave={(e) => dragLeaveHandler(e)}
                                                            onDragEnd={(e) => dragEndHandler(e)}
                                                            onDragOver={(e) => dragOverHandler(e, task)}
                                                            onDrop={(e) => dragDropHandler(e, task, group)}
                                                        >
                                                            <div id="div-with-title-task">
                                                                {task.title}
                                                                {task.file.length > 0 || task.count_comments > 0 || task.subtasks.length > 0 ?
                                                                    <div id="icon-right-for-task">
                                                                        {task.file.length > 0 ?
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + "paperclip.svg"}
                                                                                >
                                                                                </img>
                                                                                {task.file.length}
                                                                            </div>: null
                                                                        }
                                                                        {task.count_comments > 0 ? 
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + "comment.svg"}
                                                                                >
                                                                                </img> {task.count_comments}
                                                                            </div>: null
                                                                        }
                                                                        {task.subtasks.length > 0 ?
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + 'connect-btn.svg'}
                                                                                >
                                                                                </img>
                                                                                {task.subtasks.length}
                                                                            </div>: null
                                                                        }
                                                                    </div>: null
                                                                }
                                                            </div>
                                                            <div id="helthdiv">
                                                                <div id="divforinfo">
                                                                    <div id="div-main-info-task">
                                                                        <div id="div-for-performer-column-image">
                                                                            {(performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                                                return (
                                                                                    <img
                                                                                        key={kindex}
                                                                                        id="userphoto"
                                                                                        src={k.image}
                                                                                        alt=""
                                                                                    />
                                                                                )
                                                                            })).length === 0 ? 
                                                                                <img
                                                                                    id="user-template-photo"
                                                                                    src={apiUrlFrontend + "person-square.svg"}
                                                                                    alt=""
                                                                                    // eslint-disable-next-line
                                                                                />: (performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                                                    if (kindex === 0) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 1) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 2) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 3) {
                                                                                        return (
                                                                                            <label>...</label>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            )}
                                                                        </div>
                                                                        {task.label.map(lab => lab).slice(0, 3).length ?
                                                                            <div id="divforlabel">
                                                                                {task.label.map(lab => lab).slice(0, 3).map((label, labelIndex) => (
                                                                                    <div key={labelIndex} id="divforlabeltitle">
                                                                                        <div id="circle"
                                                                                            style={{background: label.color}}
                                                                                        />
                                                                                        <div id="label-name-color">
                                                                                            {label.name.slice(0, 15)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>: null
                                                                        }
                                                                        {task.label.map(lab => lab).slice(3, 6).length ?
                                                                            <div id="divforlabel">
                                                                                {task.label.map(lab => lab).slice(3, 6).map((label, labelIndex) => (
                                                                                    <div key={labelIndex} id="divforlabeltitle">
                                                                                        <div id="circle"
                                                                                            style={{background: label.color}}
                                                                                        />
                                                                                        <div id="label-name-color">
                                                                                            {label.name.slice(0, 15)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>: null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {task.deadline ?
                                                                <div
                                                                    style={{display: 'flex', color: ((new Date(task.deadline) - new Date()) / 86400000) <= 3 ?
                                                                        "rgb(230, 74, 49)": ((new Date(task.deadline) - new Date()) / 86400000) <= 7 ?
                                                                        'rgb(238, 185, 83)': 'rgb(121, 181, 237)', fontSize: "16px", fontFamily: "'Roboto', sans-serif", fontWeight: "bolder"}}
                                                                >
                                                                    {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                                                    {((new Date(task.deadline) - new Date()) / 86400000) < -0.9 && group.system_status_name !== 'Completed' ?
                                                                        <div id="overdue-deadline">
                                                                            <img id="icon-for-right-task"
                                                                                alt=""
                                                                                src={apiUrlFrontend + "overdue-task-deadline.svg"}
                                                                            >
                                                                            </img> {Math.floor(((new Date(task.deadline) - new Date()) / 86400000))}д
                                                                        </div>: null
                                                                    }
                                                                </div>: null
                                                            }
                                                        </button>
                                                    : task.priority > 3 && task.priority <= 7 ?
                                                        <button id="detailtask"
                                                            draggable={true} 
                                                            onClick={() => handleOpendetailTasks({taskid: task.task_id})}
                                                            onMouseDown={(e) => handleOpendetailTasksNewPin(e, {taskid: task.task_id})}
                                                            onDragStart={(e) => dragStartHandler(e, task, group)}
                                                            onDragLeave={(e) => dragLeaveHandler(e)}
                                                            onDragEnd={(e) => dragEndHandler(e)}
                                                            onDragOver={(e) => dragOverHandler(e, task)}
                                                            onDrop={(e) => dragDropHandler(e, task, group)}
                                                        >
                                                            <div id="div-with-title-task">
                                                                {task.title}
                                                                {task.file.length > 0 || task.count_comments > 0 || task.subtasks.length > 0 ?
                                                                    <div id="icon-right-for-task">
                                                                        {task.file.length > 0 ?
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + "paperclip.svg"}
                                                                                >
                                                                                </img>
                                                                                {task.file.length}
                                                                            </div>: null
                                                                        }
                                                                        {task.count_comments > 0 ? 
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + "comment.svg"}
                                                                                >
                                                                                </img> {task.count_comments}
                                                                            </div>: null
                                                                        }
                                                                        {task.subtasks.length > 0 ?
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + 'connect-btn.svg'}
                                                                                >
                                                                                </img>
                                                                                {task.subtasks.length}
                                                                            </div>: null
                                                                        }
                                                                    </div>: null
                                                                }
                                                            </div>
                                                            <div id="helthdiv">
                                                                <div id="divforinfo">
                                                                    <div id="div-main-info-task">
                                                                        <div id="div-for-performer-column-image">
                                                                            {(performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                                                return(
                                                                                    <img
                                                                                        key={kindex}
                                                                                        id="userphoto"
                                                                                        src={k.image}
                                                                                        alt=""
                                                                                    />
                                                                                )
                                                                            })).length === 0 ? 
                                                                                <img
                                                                                    id="user-template-photo"
                                                                                    src={apiUrlFrontend + "person-square.svg"}
                                                                                    alt=""
                                                                                    // eslint-disable-next-line
                                                                                />: (performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                                                    if (kindex === 0) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 1) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 2) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 3) {
                                                                                        return (
                                                                                            <label>...</label>
                                                                                        )
                                                                                    }
                                                                                }))
                                                                            }
                                                                        </div>
                                                                        {task.label.map(lab => lab).slice(0, 3).length ?
                                                                            <div id="divforlabel">
                                                                                {task.label.map(lab => lab).slice(0, 3).map((label, labelIndex) => (
                                                                                    <div key={labelIndex} id="divforlabeltitle">
                                                                                        <div id="circle"
                                                                                            style={{background: label.color}}
                                                                                        />
                                                                                        <div id="label-name-color">
                                                                                            {label.name.slice(0, 15)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>: null
                                                                        }
                                                                        {task.label.map(lab => lab).slice(3, 6).length ?
                                                                            <div id="divforlabel">
                                                                                {task.label.map(lab => lab).slice(3, 6).map((label, labelIndex) => (
                                                                                    <div key={labelIndex} id="divforlabeltitle">
                                                                                        <div id="circle"
                                                                                            style={{background: label.color}}
                                                                                        />
                                                                                        <div id="label-name-color">
                                                                                            {label.name.slice(0, 15)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>: null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {task.deadline ? 
                                                                <div 
                                                                    style={{display: 'flex', color: ((new Date(task.deadline) - new Date()) / 86400000) <= 3 ?
                                                                        "rgb(230, 74, 49)": ((new Date(task.deadline) - new Date()) / 86400000) <= 7 ?
                                                                        'rgb(238, 185, 83)': 'rgb(121, 181, 237)', fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bolder"
                                                                    }}
                                                                >
                                                                    {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                                                    {((new Date(task.deadline) - new Date()) / 86400000) < -0.9 && group.system_status_name !== 'Completed' ?
                                                                        <div id="overdue-deadline">
                                                                            <img id="icon-for-right-task"
                                                                                alt=""
                                                                                src={apiUrlFrontend + "overdue-task-deadline.svg"}
                                                                            >
                                                                            </img> {Math.floor(((new Date(task.deadline) - new Date()) / 86400000))}д
                                                                        </div>: null
                                                                    }
                                                                </div>: null
                                                            }
                                                        </button>:
                                                        <button id="detailtask" 
                                                            draggable={true} 
                                                            onClick={() => handleOpendetailTasks({taskid: task.task_id})}
                                                            onMouseDown={(e) => handleOpendetailTasksNewPin(e, {taskid: task.task_id})}
                                                            onDragStart={(e) => dragStartHandler(e, task, group)}
                                                            onDragLeave={(e) => dragLeaveHandler(e)}
                                                            onDragEnd={(e) => dragEndHandler(e)}
                                                            onDragOver={(e) => dragOverHandler(e, task)}
                                                            onDrop={(e) => dragDropHandler(e, task, group)}
                                                        >
                                                            <div id="div-with-title-task">
                                                                {task.title}
                                                                {task.file.length > 0 || task.count_comments > 0 || task.subtasks.length > 0 ?
                                                                    <div id="icon-right-for-task">
                                                                        {task.file.length > 0 ?
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + "paperclip.svg"}
                                                                                >
                                                                                </img>
                                                                                {task.file.length}
                                                                            </div>: null
                                                                        }
                                                                        {task.count_comments > 0 ? 
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + "comment.svg"}
                                                                                >
                                                                                </img> {task.count_comments}
                                                                            </div>: null
                                                                        }
                                                                        {task.subtasks.length > 0 ?
                                                                            <div id="task-file-length">
                                                                                <img id="icon-for-right-task"
                                                                                    alt=""
                                                                                    src={apiUrlFrontend + 'connect-btn.svg'}
                                                                                >
                                                                                </img>
                                                                                {task.subtasks.length}
                                                                            </div>: null
                                                                        }
                                                                    </div>: null
                                                                }
                                                            </div>
                                                            <div id="helthdiv">
                                                                <div id="divforinfo">
                                                                    <div id="div-main-info-task">
                                                                        <div id="div-for-performer-column-image">
                                                                            {(performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                                                return (
                                                                                    <img
                                                                                        key={kindex}
                                                                                        id="userphoto"
                                                                                        src={k.image}
                                                                                        alt=""
                                                                                    />
                                                                                )
                                                                            })).length === 0 ? 
                                                                                <img
                                                                                    id="user-template-photo"
                                                                                    src={apiUrlFrontend + "person-square.svg"}
                                                                                    alt=""
                                                                                    // eslint-disable-next-line
                                                                                />: (performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                                                    if (kindex === 0) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 1) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 2) {
                                                                                        return (
                                                                                            <img
                                                                                                key={kindex}
                                                                                                id="userphoto"
                                                                                                src={k.image}
                                                                                                alt=""
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    else if (kindex === 3) {
                                                                                        return (
                                                                                            <label>...</label>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            )}
                                                                        </div>
                                                                        {task.label.map(lab => lab).slice(0, 3).length ?
                                                                            <div id="divforlabel">
                                                                                {task.label.map(lab => lab).slice(0, 3).map((label, labelIndex) => (
                                                                                    <div key={labelIndex} id="divforlabeltitle">
                                                                                        <div id="circle" style={{background: label.color}}/>
                                                                                        <div id="label-name-color">
                                                                                            {label.name.slice(0, 15)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>: null
                                                                        }
                                                                        {task.label.map(lab => lab).slice(3, 6).length ?
                                                                            <div id="divforlabel">
                                                                            {task.label.map(lab => lab).slice(3, 6).map((label, labelIndex) => (
                                                                                <div key={labelIndex} id="divforlabeltitle">
                                                                                    <div id="circle" style={{background: label.color}}/>
                                                                                    <div id="label-name-color">
                                                                                        {label.name.slice(0, 15)}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            </div>: null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {task.deadline ?
                                                                <div 
                                                                    style={{display: 'flex', color: ((new Date(task.deadline) - new Date()) / 86400000) <= 3 ?
                                                                        "rgb(230, 74, 49)": ((new Date(task.deadline) - new Date()) / 86400000) <= 7 ?
                                                                        'rgb(238, 185, 83)': 'rgb(121, 181, 237)', fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bolder"
                                                                    }}
                                                                >
                                                                    {format(new Date(task.deadline), 'dd.MM.yyyy')}
                                                                    {((new Date(task.deadline) - new Date()) / 86400000) < -0.9 && group.system_status_name !== 'Completed' ?
                                                                        <div id="overdue-deadline">
                                                                            <img id="icon-for-right-task"
                                                                                alt=""
                                                                                src={apiUrlFrontend + "overdue-task-deadline.svg"}
                                                                            >
                                                                            </img> {Math.floor(((new Date(task.deadline) - new Date()) / 86400000))}д
                                                                        </div>: null
                                                                    }
                                                                </div>: null
                                                            }
                                                        </button>
                                                    }   
                                                </div>
                                            ))}
                                        </div>:
                                        <div id="divwiththegrouptasks"
                                            onDragOver={(e) => dragOverHandler(e)}
                                            onDrop={(e) => dragDropHandlerForGroup(e, null, group)}
                                        >
                                        </div>: null
                                    }
                                </div>
                            <div id="drop-for-group-div"
                                onDragOver={(e) => dragOverHandler(e, group)}
                                onDragEnd={(e) => dragEndHandler(e)}
                                onDrop={(e) => dragDropHandlerGroup(e, group)}
                                onDragLeave={(e) => dragLeaveHandler(e)}
                            >
                            </div>
                        </div>
                    ))}
                    {canAddGroup ? 
                        <button id="createnewgroup" onClick={handlecreategroup}>
                            Добавить колонку
                        </button>: null
                    }
                </div>
            </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
    );
}

export default DetailBoard;