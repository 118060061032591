import React, { useState } from 'react';
import './styles/Profile.css';
import api from '/app/frontend/src/api';
import TelegramLoginButton from 'react-telegram-login';
import { Tooltip } from 'react-tooltip';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';

const Profile = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const [login, setLogin] = useState('');
    const [user, setUser] = useState('');
    const [surname, setUserSurname] = useState('');
    const [name, setUserName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [post, setPost] = useState('');
    const [checkisuser, setCheck] = useState('');
    const [notificationTg, setNotificationTg] = useState();
    const [notificationMail, setNotificationMail] = useState();
    const [checkEdit, setcheckEdit] = useState(false);
    const [editUserTrue, seteditUserTrue] = useState(false);
    const [notThisUser, setNotThisUser] = useState([]);
    const [userid, setuserid] = useState('');
    const [selectedpost, setselectedpost] = useState([]);
    const [canBlockUser, setCanBlockUser] = useState(false);
    const [userpost, setuserpost] = useState([]);
    const [email, setEmail] = useState([]);
    const [notificationTaskMovement, setNotificationTaskMovement] = useState();
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const telegramBotName = process.env.REACT_APP_TELEGRAM_BOT_NAME;
    const [notificationLabelChange, setNotificationLabelChange] = useState();
    const [notificationTitleChange, setNotificationTitleChange] = useState();
    const [notificationDescriptionChange, setNotificationDescriptionChange] = useState();
    const [notificationPriorityChange, setNotificationPriorityChange] = useState();
    const [notificationFileChange, setNotificationFileChange] = useState();
    const [notificationInviteOrganizationChange, setNotificationInviteOrganization] = useState();
    const [image, setImage] = useState();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const handleGoToUnauth = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
        window.location.href = '/login';
    };
    
    const base64ToFile = (base64, filename) => {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);
        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleCropImageChanges = async () => {
        try {
            const croppedImageBase64 = await getCroppedImg(image, croppedAreaPixels);
            console.log(croppedImageBase64);
            const imageFile = base64ToFile(croppedImageBase64, `croppedImage_${Date.now()}_${Math.random().toString(36).substr(2, 9)}.jpg`);
            const formData = new FormData();
            formData.append('image', imageFile);
            updatePhotoProfile(formData);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePostSelect = (event) =>{
        const groups = event.target.value;
        if (groups === "Должность *") {
            // setFilteredTask(task)
        }
        else{
            setselectedpost(groups);
        }
    }
    const handleUserSurnameChange = (event) => {
        setUserSurname(event.target.value);
    };
    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };
    const handlePatronymicChange = (event) => {
        setPatronymic(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleLoginChange = (event) => {
        setLogin(event.target.value);
    };

    function updateAll() {
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const path = window.location.pathname;
                const parts = path.split('/');
                const username = parts[2];
                if (username !== response.data.username) {
                    api.get(`${apiUrl}api/identity/users/search-by-username/`, {
                        params: {
                            'username': username
                        },
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }).then((response) => {
                        if (response.status === 200) { 
                            setCheck(username);
                            console.log(response.data)
                            setNotThisUser(response.data[0]);
                            checkIsedituser();
                            setuserid(response.data[0].id);
                            setUserName(response.data[0].first_name);
                            setUserSurname(response.data[0].last_name);
                            setPatronymic(response.data[0].patronymic);
                            setuserpost(response.data[0].post_name);
                            setEmail(response.data[0].email);
                            setLogin(response.data[0].username);
                        }
                    }).catch(error => {
                        if (error.response.status === 404) {
                            alert("Пользователь не найден");
                            window.location.href = '/main';
                        }
                    });
                }
                else {
                    setCheck(username);
                    setUser(response.data);
                    setUserName(response.data.first_name);
                    setUserSurname(response.data.last_name);
                    setEmail(response.data.email);
                    setPatronymic(response.data.patronymic);
                    setNotificationMail(response.data.notification_mail);
                    setNotificationTg(response.data.notification_telegram);
                    setNotificationTaskMovement(response.data.notification_task_movement);
                    setNotificationLabelChange(response.data.notification_label_change);
                    setNotificationTitleChange(response.data.notification_title_task_change);
                    setNotificationDescriptionChange(response.data.notification_description_task_change);
                    setNotificationPriorityChange(response.data.notification_priority_task_change);
                    setNotificationFileChange(response.data.notification_file_change);
                    setNotificationInviteOrganization(response.data.notification_invite_organization);
                    setLogin(response.data.username);
                }
            }
        }).catch(error => {
        });
        api.get(`${apiUrl}api/identity/post/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setPost(response.data);
                if (response.data.includes(post)) {
                    setselectedpost(post);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    }

    React.useEffect(() => {
        updateAll()
        // eslint-disable-next-line
    }, []);

    async function checkIsedituser(){
        await api.get(`${apiUrl}api/v1/user/permissions/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
          }).then((response) => {
            if (response.status === 200) {
                console.log(response.data)
                if (response.data.includes('Can block user')) {
                    setCanBlockUser(true);
                }
                if (response.data.includes('owner_organization')) {
                    seteditUserTrue(true);
                    setCanBlockUser(true);
                }
                if (response.data.includes('Can change Пользователь')) {
                    seteditUserTrue(true);
                }
                else{
                    seteditUserTrue(false);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    };

    const handleSaveChanges = (event) => {
        event.preventDefault()
        if (name === '' || surname === '' || patronymic === '') {
            alert("Заполните все поля");
        }
        else{
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'first_name': name,
                'last_name': surname,
                'patronymic': patronymic,
                'email': email,
                'username': login,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    alert("Данные обновленны");
                    window.location.href = `${login}`;
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 26214400) {
                alert("Ошибка: Файл должен быть не более 25MB");
            }
            else{
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    function updatePhotoProfile(acceptedFiles) {       
        api.patch(`${apiUrl}api/identity/auth/users/me/`, acceptedFiles, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Фото успешно добавлено");
                setImage();
                updateAll();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationMail = (notification_mail) => {
        setNotificationMail(!notificationMail);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_mail': notification_mail,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationTaskMovement = (notification_task_movement) => {
        setNotificationTaskMovement(!notificationTaskMovement);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_task_movement': notification_task_movement,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationLabelChange = (notification_label_change) => {
        setNotificationLabelChange(!notificationLabelChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_label_change': notification_label_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationFileChange = (notification_file_change) => {
        setNotificationFileChange(!notificationFileChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_file_change': notification_file_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationTitleChange = (notification_title_change) => {
        setNotificationTitleChange(!notificationTitleChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_title_task_change': notification_title_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationDescriptionChange = (notification_description_change) => {
        setNotificationDescriptionChange(!notificationDescriptionChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_description_task_change': notification_description_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationPriorityChange = (notification_priority_change) => {
        setNotificationPriorityChange(!notificationPriorityChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_priority_task_change': notification_priority_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationInviteOrganizationChange = (notification_invite_change) => {
        setNotificationInviteOrganization(!notificationInviteOrganizationChange);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_invite_organization': notification_invite_change,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeNotificationTg = (notification_tg) => {
        setNotificationTg(!notificationTg);
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'notification_telegram': notification_tg,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const changePassword = (event) => {
        console.log(user);
        event.preventDefault();
        api.post(`${apiUrl}api/identity/auth/users/reset_password/`, {
            email: user.email
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) { 
                alert("Письмо для смены пароля отправлено на электронную почту. Следуйте указанием из письма.");
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const changePasswordForAdmin = (event) => {
        event.preventDefault();
        api.post(`${apiUrl}api/identity/auth/users/reset_password/`, {
            email: email
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) { 
                alert("Письмо для смены пароля отправлено на электронную почту. Следуйте указанием из письма.")
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleCanEdit = (event) => {
        event.preventDefault();
        setcheckEdit(!checkEdit);
    };

    const handleSaveChangesNotUser = (event) => {
        event.preventDefault();
        if (!email || !selectedpost || !login) {
            alert('Заполните поля');
        }
        api.patch(`${apiUrl}api/identity/users/${userid}/`, {
            'first_name': name,
            'last_name': surname,
            'patronymic': patronymic,
            'post': selectedpost,
            'email': email,
            'username': login,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Успешно обновленно");
                setcheckEdit(false);
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (error.response.data.email) {
                alert(`Корректно заполните поле с электронной почтой`);
            }
            if (error.response.data.error) {
                alert(`Код ошибки: ${status}, ${error.response.data.error}`, error);
            }
            if (error.response.data.post) {
                alert(`Код ошибки: ${status}, Выберите должность`, error);
            }
            if (error.response.data.username) {
                alert(`Корректно заполните поле с login пользователя`);
            }
        });
    };

    const handleTelegramUnconnect = (event) => {
        event.preventDefault();
        api.patch(`${apiUrl}api/identity/users/${user.id}/`, {
            user_telegram_id: null
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Успешно обновленно");
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChoiceOrganization = (event) => {
        event.preventDefault();
        window.location.href='/choice-organization';
    };

    const handleTelegramResponse = (response) => {
        if (user.id) {
            api.get(`${apiUrl}api/identity/users/${user.id}/connection-telegram/`, {
                params:response,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.status === 200) {
                    alert("Телеграм id успешно привязан");
                }
            }).catch(error => {
                const status = error.response ? error.response.status : null;
                alert(`Ошибка: ${error.response.data.detail}, Код ошибки: ${status}`, error);
            });
        }
    };

    const handleChangeUserAccess = (e) => {
        e.preventDefault();
        api.get(`${apiUrl}api/identity/users/${notThisUser.id}/delete-from-organization/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Пользователь больше не имеет доступа к вашей организации");
                window.location.href = '/main';
            }
        }).catch(error => {
            const status = error.response ? error.response.status : null;
            if (error.response.status === 403 && error.response.data.detail === 'Unable to block organization owner') {
                alert(`Код ошибки: ${status}, Невозможно заблокировать владельца организации.`, error);
            }
            else if (error.response.data.error) {
                alert(`Код ошибки: ${status}, ${error.response.data.error}`, error);
            }
        });
    };

    document.title = `Профиль ${user.username ? user.username: notThisUser.username} - ТвояЗадача`;

    return (
            <div className="main-profile">
                {user.username === checkisuser ?
                    <div id="main-profile-div">
                        <div id="divprofileform">
                            <div id="photo-user-update-div">
                                {image ?
                                    <div id="cropped-image">
                                        {image && (
                                            <Cropper
                                                image={image}
                                                crop={crop}
                                                rotation={rotation}
                                                zoom={zoom}
                                                aspect={1}
                                                onCropChange={setCrop}
                                                onRotationChange={setRotation}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        )}
                                    </div>: null
                                }
                                {image ?
                                    <button id="save-changes-cropp-photo" onClick={handleCropImageChanges}>
                                        Обрезать
                                    </button>: null
                                }
                                {user.image && !image ? 
                                    <img id="photoprofile"
                                        src={`${user.image}`}
                                        alt=''
                                    />:
                                    !image ?
                                        <img id="photoprofile"
                                            src={apiUrlFrontend + "person-square.svg"}
                                            alt=''
                                        />:
                                    null
                                }
                                {!image ?
                                    <label id="mainusername">{user.username}</label>: null
                                }
                                {checkEdit === true ?
                                    <button id="save-profile" onClick={(event) => handleSaveChanges(event)}>
                                        Сохранить
                                    </button>: null
                                }
                                <label className="input-file">
                                    <input id="add-photo-profile" type="file" accept="image/*" onChange={handleImageChange}/>            
                                    <span id="input-file-text">Обновить фото</span>
                                </label>
                                <button id="edit-profile" onClick={(event) => handleCanEdit(event)}>
                                    Редактировать
                                </button>
                                <button id="changeuserpassword" onClick={(event) => changePassword(event)}>
                                    Сменить пароль
                                </button>
                                <label id="hintforpassword">
                                    После смены пароля потребуется снова войти в аккаунт
                                </label>
                                <button id="go-to-unauth-btn" onClick={() => handleGoToUnauth()}>
                                    Выйти из аккаунта
                                </button>
                            </div>
                            <form id="profileform">
                                {checkEdit === false ? 
                                    <div id="div-column-user-info-inputs" style={{backgroundColor: '#393A45', paddingLeft: '15px', paddingRight: '15px', borderRadius: '0px 10px 10px 0px'}}> 
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="surname"
                                            value={surname}
                                            onChange={handleUserSurnameChange}
                                            placeholder={user.last_name ? user.last_name : "Фамилия"}
                                        />
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleUserNameChange}
                                            placeholder={user.first_name ? user.first_name : "Имя"}
                                        />
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="patronymic"
                                            value={patronymic}
                                            onChange={handlePatronymicChange}
                                            placeholder={user.patronymic ? user.patronymic : "Отчество"}
                                        />
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder={user.email ? user.email : "Электронная почта"}
                                        />
                                        <label id="userorganization">
                                            {user.organization ? user.organization : "Организация"}
                                        </label>
                                        <label id="userpost">
                                            {user.post_name ? user.post_name : "Должность"}
                                        </label>
                                        {user.available_organization.length > 1 ?
                                            <button id="change-organization-btn" onClick={(event) => handleChoiceOrganization(event)}>
                                                Сменить организацию
                                            </button>: null
                                        }
                                        {user.user_telegram_id ?
                                            <button  id="btn-unconnect-tg" onClick={(e) => handleTelegramUnconnect(e)}>
                                                Отвязать телеграм
                                            </button>
                                            :   
                                            <div id="div-tg-btn-connect"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Привязать телеграм"
                                            >
                                                <TelegramLoginButton className="custom-tg-button" usePic={false} dataOnauth={handleTelegramResponse} botName={telegramBotName}/>
                                            </div>
                                        }
                                    </div>:
                                    <div id="div-column-user-info-inputs" style={{backgroundColor: '#393A45', paddingLeft: '10px', paddingRight: '15px', borderRadius: '0px 10px 10px 0px'}}>
                                        <input 
                                            type="text"
                                            id="surname"
                                            value={surname}
                                            onChange={handleUserSurnameChange}
                                            placeholder={user.last_name ? user.last_name : "Фамилия"}
                                        />
                                        <input
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleUserNameChange}
                                            placeholder={user.first_name ? user.first_name : "Имя"}
                                        />
                                        <input
                                            type="text"
                                            id="patronymic"
                                            value={patronymic}
                                            onChange={handlePatronymicChange}
                                            placeholder={user.patronymic ? user.patronymic : "Отчество"}
                                        />

                                        <input
                                            type="text"
                                            id="patronymic"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder={user.email ? user.email : "Электронная почта"}
                                        />
                                        <input
                                            type="text"
                                            id="patronymic"
                                            value={login}
                                            onChange={handleLoginChange}
                                            placeholder={user.username ? user.username : "Логин"}
                                        />
                                    </div>
                                }
                            </form>
                        </div>
                        <div id="div-column-user-info" style={{backgroundColor: '#393A45', marginLeft: '20px', borderRadius: '10px', height: 'fit-content', padding: '15px'}}>
                            <label id="notification">
                                Получать уведомление в телеграм
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationTg}
                                onChange={() => handleChangeNotificationTg(!notificationTg)}
                            />
                            <label id="notification">
                                Получать уведомление на почту
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationMail}
                                onChange={() => handleChangeNotificationMail(!notificationMail)}
                            />
                            <label id="notification">
                                Получать уведомление о движении задач
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationTaskMovement}
                                onChange={() => handleChangeNotificationTaskMovement(!notificationTaskMovement)}
                            />
                            <label id="notification">
                                Получать уведомление о назначении/смене меток
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationLabelChange}
                                onChange={() => handleChangeNotificationLabelChange(!notificationLabelChange)}
                            />
                            <label id="notification">
                                Получать уведомление о назначении/смене файлов
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationFileChange}
                                onChange={() => handleChangeNotificationFileChange(!notificationFileChange)}
                            />
                        </div>
                        <div id="div-column-user-info" style={{backgroundColor: '#393A45', marginLeft: '20px', borderRadius: '10px', height: 'fit-content', padding: '15px'}}>
                            <label id="notification">
                                Получать уведомление о изменении заголовка задачи
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationTitleChange}
                                onChange={() => handleChangeNotificationTitleChange(!notificationTitleChange)}
                            />
                            <label id="notification">
                                Получать уведомление о изменении описания задачи
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationDescriptionChange}
                                onChange={() => handleChangeNotificationDescriptionChange(!notificationDescriptionChange)}
                            />
                            <label id="notification">
                                Получать уведомление о изменении приоритета задачи
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationPriorityChange}
                                onChange={() => handleChangeNotificationPriorityChange(!notificationPriorityChange)}
                            />
                            <label id="notification">
                                Получать уведомление о приглашении в организацию
                            </label>
                            <input id="status-notification-mail"
                                type="checkbox"
                                checked={notificationInviteOrganizationChange}
                                onChange={() => handleChangeNotificationInviteOrganizationChange(!notificationInviteOrganizationChange)}
                            />
                        </div>
                        <div id="div-column-user-info" style={{backgroundColor: '#393A45', marginLeft: '20px', borderRadius: '10px', height: 'fit-content', padding: '15px'}}>
                        </div>
                    </div>: 
                    <div id="main-profile-div">
                        <div id="divprofileform">
                            <form id="profileform">
                                <div id="photo-user-update-div">
                                    {notThisUser.image ? 
                                        <img id="photoprofile"
                                            src={`${notThisUser.image}`}
                                            alt=''
                                        />:
                                        <img id="photoprofile"
                                            src={apiUrlFrontend + "person-square.svg"}
                                            alt=''
                                        />
                                    }
                                    <label id="mainusername">{notThisUser.username}</label>
                                    {checkEdit === true ?
                                        <button id="save-profile" onClick={(event) => handleSaveChangesNotUser(event)}>
                                            Сохранить
                                        </button>: null
                                    }
                                    {editUserTrue ?
                                        <button id="edit-profile" onClick={(event) => handleCanEdit(event)}>
                                            Редактировать
                                        </button>: null
                                    }
                                </div>
                                {checkEdit === false ? 
                                    <div id="div-column">
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="surname"
                                            value={surname}
                                            onChange={handleUserSurnameChange}
                                            placeholder={notThisUser.last_name ? notThisUser.last_name: "Фамилия"}
                                        />
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleUserNameChange}
                                            placeholder={notThisUser.first_name ? notThisUser.first_name: "Имя"}
                                        />
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="patronymic"
                                            value={patronymic}
                                            onChange={handlePatronymicChange}
                                            placeholder={notThisUser.patronymic ? notThisUser.patronymic: "Отчество"}
                                        />
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="patronymic"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder={notThisUser.email ? notThisUser.email: "Электронная почта"}
                                        />
                                        <label id="userorganization">
                                            {notThisUser.organization}
                                        </label>
                                        {checkEdit === true ?
                                            <select id="post-select-change" onChange={handlePostSelect}>
                                                <option>Должность *</option>
                                                {post.map((group, index) => (
                                                    <option key={index} value={group.id}>{group.name}</option>
                                                ))}
                                            </select>:
                                            <input
                                                type="text"
                                                id="patronymic"
                                                value={userpost}
                                                placeholder={userpost}
                                            />
                                        }
                                    </div>:
                                    <div id="div-column">
                                        <input 
                                            type="text"
                                            id="surname"
                                            value={surname}
                                            onChange={handleUserSurnameChange}
                                            placeholder={notThisUser.last_name ? notThisUser.last_name: "Фамилия"}
                                        />
                                        <input
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleUserNameChange}
                                            placeholder={notThisUser.first_name ? notThisUser.first_name: "Имя"}
                                        />
                                        <input
                                            type="text"
                                            id="patronymic"
                                            value={patronymic}
                                            onChange={handlePatronymicChange}
                                            placeholder={notThisUser.patronymic ? notThisUser.patronymic: "Отчество"}
                                        />
                                        <input
                                            type="text"
                                            id="patronymic"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder={notThisUser.email ? notThisUser.email: "Электронная почта"}
                                        />
                                        <input
                                            type="text"
                                            id="patronymic"
                                            value={login}
                                            onChange={handleLoginChange}
                                            placeholder={user.username}
                                        />
                                        <label id="userorganization">
                                            {notThisUser.organization}
                                        </label>
                                        {checkEdit === true ?
                                            <select id="post-select-change" onChange={handlePostSelect}>
                                                <option>Должность *</option>
                                                {post.map((group, index) => (
                                                    <option key={index} value={group.id}>{group.name}</option>
                                                ))}
                                            </select>:
                                            <input
                                                type="text"
                                                id="post"
                                                value={userpost}
                                                placeholder={userpost}
                                            />
                                        }
                                    </div>
                                }
                                <div id="div-column" style={{justifyContent: 'center'}}>
                                    <button id="changeuserpassword" onClick={(event) => changePasswordForAdmin(event)}>
                                        Сменить пароль
                                    </button>
                                    <label id="hintforpassword">
                                        Отправляет письмо пользователю на почту с предложением сменить пароль
                                    </label>
                                    {canBlockUser ?
                                        <div style={{display: "flex", flexDirection: "column", marginTop: '5px'}}>
                                            <button id="changeuserpassword" onClick={(e) => handleChangeUserAccess(e)}>
                                                Заблокировать пользователя
                                            </button>
                                            <label id="hintforpassword">
                                                Для разблокировки требуется снова пригласить пользователя в организацию
                                            </label>
                                        </div>: null
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                }
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
    );
}

export default Profile;