import React, { useState } from 'react';
import axios from 'axios';
import TelegramLoginButton from 'react-telegram-login';
import './styles/Login.css';

const LoginPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const telegramBotName = process.env.REACT_APP_TELEGRAM_BOT_NAME;

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTelegramResponse = response => {
    axios.get(`${apiUrl}api/`, {
      params:response 
    }).then(response => {
      if (response.status === 200){
        const tokenJson = response.data;
        if (tokenJson.access_token && tokenJson.refresh_token) {
          localStorage.setItem('token', tokenJson.access_token);
          localStorage.setItem('refreshToken', tokenJson.refresh_token);
          window.location.href = "/choice-organization";
        }
        else {
          alert("Ваш аккаунт не найден, обратитесь к администратору вашей организации.");  
        }
      }
      else{
        alert("Ваш аккаунт не найден, обратитесь к администратору вашей организации.");
      }
    }).catch(error => {
      const status = error.response ? error.response.status : null;
      alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error);
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${apiUrl}api/identity/auth/jwt/create/`, {
      username: username,
      password: password
    })
    .then(response => {
      const { access } = response.data;
      localStorage.setItem('token', access);
      localStorage.setItem('refreshToken', response.data.refresh);
      window.location.href = "/choice-organization";
    })
    .catch(error =>{
      const status = error.response ? error.response.status : null;
      if (error.response.status === 401) {
        alert('Вы ввели неверный логин или пароль!');
      }
      else {
        alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error);
      }
    });
  };

  document.title = "Вход - ТвояЗадача";

  return (
    <div className="login-page" style={{width: "100%", height: "100%"}}>
      <form onSubmit={handleSubmit} className="login-form">
        <h1>Авторизация</h1>
        <div className="form-group-login">
          <input
            type="text"
            id="username-txt"
            value={username}
            onChange={handleUsernameChange}
            placeholder='Логин' 
            required
          />
        </div>
        <div className="form-grouplogin">
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder='Пароль'
            required
          />
        </div>
        <div id="other-auth-button">
          <button id="login" type="submit">Войти</button>
          <div id="div-tg-btn">
            <TelegramLoginButton id="tgbutton" buttonSize="medium" dataOnauth={handleTelegramResponse} botName={telegramBotName}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
