import React from 'react';

const DevelopersInfo = () => {

    return (
        <div className="DevelopersInfo">
            <h1 id='txt'> Это DevelopersInfo</h1>
        </div>
    );
}

export default DevelopersInfo;