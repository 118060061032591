import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1 id='txt'> Это PrivacyPolicy</h1>
        </div>
    );
}

export default PrivacyPolicy;